module.exports = {
    commen: {
      steemAccoutPlaceHolder: "Steem 账号",
      hiveAccountPlaceHolder: "Hive 账号",
      loginByKeychain: "使用Keychain登录",
      loging: "登录中...",
      crowdstaking: "质押",
      optional: "(可选)",
      connectMetamask: '连接Metamask',
      delegateFee: '代理手续费',
  
      apy: "APR",
      balance: "余额",
      max: "最大",
      complete: "已完成",
      now: "现在",
      poster: "Poster",
      more: '更多',
      detail:'详情',
      activities: '动作',
      administor: '管理员',
      joinDate: '加入日期',
      noData: '没有数据',
      pageNotFound: "页面不存在！",
      backHome: "回到首页",
      amount: "数量",
      earned: 'EARNED',
      voted: "VOTED",
      staked: "STAKED",
      delegated: "DELEGATED",
      totalStake: '总质押量',
      voters: '投票用户',
      stakers: '质押用户',
      summary: '汇总',
      weeks: '周',
      or: '或者',
  
      whitePaper: "白皮书",
      nps: "Nutbox提案改善系统",
      contract: "合约",
      social: "社交信息",
      docs: "文档",
      discord: "Discord",
      blog: "博客",
      contractAddr: "合约地址",
      dashboard: "仪表盘",
      auditReport: "审计报告",
      language: "语言",
      zh: "中文",
      en: "English",
      kr: "한국어",
      my: "Malaysia",
      tr: "Türkçe",
      es: "Español",
      jp: "日本語",
      goToHome: "回到首页",
      home: '首页',
    },
    desc: {
      slogan: '快速创建您的质押经济',
      featuredCommunity: '推荐社区',
      aboutNutbox: '关于Nutbox',
      totalValue: '资产总值',
      disStrategy: '铸造策略',
      adminOnly: '只看管理员',
      youWillReceive: '你将收到: ',
      iso: 'ISO: 首次质押发行',
      isoDetail: '质押POS代币挖取社区代币。高收益率、无风险。',
      erc1155Desc: '质押您的ERC115来挖矿.',
      curation: '社交矿池可以直接将资产分配到接收者地址（通常是合约地址）。任何用户都可以执行领取操作，执行领取后的奖励将直接转移到接收者地址。',
      nutPower: 'Nut Power（NP）是Nutbox DAO的治理权。是由质押NUT生成的，解锁时间越长，获取的NP越多。它还是你获取新产生NUT的收益权。',
      farming: '质押您的代币以挖取社区代币',
      assetTip1: '如果您想使用已经存在的资产',
      assetTip2: '创建一个新的代码',
      assetTip3: `*您可以一次预挖一定量的代币带您的账号，余下的通过之后的分发策略进行发放。`
    },
    operation: {
      withdraw: "取消质押",
      harvest: "收获",
      approveContract: "授权合约",
      approveCommunity: '授权社区',
      approvePool: '授权矿池',
      connectMetamask: "连接Metamask",
      change: '切换',
      search: "搜索",
      userlogin: "登录",
      uploadLogo: "上传Logo",
      uploadPoster: "上传封面",
      sign: "签名并上传",
      hide: '隐藏',
      cancel: "取消",
      commit: "提交",
      complete: "完成",
      confirm: "确认",
      create: "创建",
      add: "添加",
      update: "更新",
      delegatecharge: "代理变更",
      login: "登录",
      logout: "登出",
      input: "输入",
      ok: '好的',
      copy: "复制",
      edit: "编辑",
      charge: '重置',
      redeem: "赎回",
      enter: '进入',
      approve: '授权',
      grant: '授予',
      manageCommunity: "管理社区",
      exploreCommunity:'探索社区',
      save: '保存',
      grantMintRole: '授予Mint权限',
      configurePoolRatio: '配置代理矿池',
      addDelegatePool: '添加代理矿池',
      addPool: '添加矿池',
      claim: '领取',
      recharge: "充值",
      upgrade: '升级',
      choseToken: "选择代币",
      registerAsset: "注册新资产",
      deploy: '部署'
    },
    wallet: {
      wallet: "钱包",
      connectSteem: "连接Steem钱包",
      connectHive: "连接Hive钱包",
      connectCosmos: "连接Keplr钱包",
      install: "安装",
      transfer: "转账",
      asset: "资产",
      nomination: "投票者",
      contribution: "众贷",
      balance: "余额",
      bond: "绑定中",
      unBond: "解绑中",
      redeem: "赎回",
      bindAmount: "已绑定",
      inputAmount: "输入数量",
      receiveAddress: "接收地址",
      transferAmount: "转账金额",
      unbindAmount: "解绑金额",
      intallKeplr: "请安装Keplr钱包插件"
    },
    date: {
      year: " 年 ",
      month: " 月 ",
      day: " 日 ",
      hour: " 小时 ",
      min: " 分 ",
      sec: " 秒 ",
    },
    transaction: {
      broadcasting: "正在广播交易",
      inBlock: "交易已打包进区块",
      contributeOk: "投票成功",
      withdrawOk: "提取成功",
      nominateOk: "投票成功",
      transactionOk: "交易成功",
      depositOk: "质押成功",
      withdrawOk: "取消质押成功",
      voteOk: '投票成功',
      unvoteOk: "取消投票成功",
      
    },
    stake: {
      stake: "质押挖矿",
      creaseDelegation: "代理",
      increaseDelegation: "取消代理",
      getSp: "获取SP",
      get: "获取",
      stake: "质押",
      unStake: "取消质押",
      staked: "已质押",
      changeAccount: '请切换 {type} 账号',
      changeAccountHtml: `请切换
      <span class="text-primary-0 font-bold">{type}</span>
      账号`,
      changeAddress: '请切换 {chainName} 地址',
      changeAddressHtml: `请切换
      <span class="text-primary-0 font-bold"> {chainName} </span>地址`,
      accountMismatch1: `您的 {type} 账号未绑定 {chainName} 地址, 请先将钱包中的 {type} 账号切换。`,
      accountMismatch2: `您的 {chainName} 地址未绑定到当前的 {type} 账号, 请先在钱包中切换您的 {chainName}地址`,
      accountMismatch3: `您的
      <span class="text-primary-0 font-bold">{type}</span>
      账号未绑定到当前的
      <span class="text-primary-0 font-bold">{chainName}</span>
      地址, 请先在钱包中切换您的
      <span class="text-primary-0 font-bold">{type}</span>
      账号。`,
      accountMismatch4: `您的 <span class="text-primary-0 font-bold"> {chainName} </span>
      地址未绑定当前的
      <span class="text-primary-0 font-bold"> {type}
      </span>
      账号, 请先在钱包中切换您的
      <span class="text-primary-0 font-bold"> {chainName} </span>
      地址。`,
      bindAccountTip1: `您绑定的 {type} 账号是:`,
      bindAddressTip1: '您绑定的地址是:'
    },
    tip: {
      approveSuccess: "授权成功",
      loading: "加载中，请稍后",
      connectingPolkadot: "正在连接波卡网络",
      noAuction: "没有拍卖",
      noNominations: "没有投票的节点",
      noContribuitons: "没有投票记录",
      noProject: "没有活跃的矿池",
      noCommunities: "没有社区",
      noSearchedToken: "没有对应的Token",
      noCrowdloan: "没有进行中的拍卖",
      noStakingProject: "没有可选验证者",
      picTip: "推荐尺寸为 {size} ，小于2M, 支持jpg、png、jpeg格式",
      tokenSafeTip: "您的资产将直接锁定到 {symbol} 官方的众贷模块中, 不会通过Nutbox的任何账号。",
      belowMinContribution: "最小投票量为：{min}",
      belowMinBond: "输入量过小，建议0.1以上",
      wrongNominatorAddress: "错误的投票地址",
      wrongTransferAddress: "错误的转账地址",
      inputAddressType: "请输入{relaychain}地址",
      insufficientBalance: "余额不足",
      editTip: "上传社区信息需要您的签名，此操作不会消耗您任何费用，请放心使用。",
      outOfCap: "超过上限",
      tips: "提示",
      error: "错误！",
      success: "成功!",
      warning: "警告！",
      noNeedWithdraw: "不需要提现",
      comingSoon: "敬请期待",
      clipboard: "剪切板",
      copyAddress: "复制地址：{address}",
      signing: "签名",
      picUploadFail: "上传图片失败，请重试",
      largeImg: '图片大小不能超过2M',
      outOfUsage: '操作太频繁，请稍后再试',
      uploadLogo: "请上传图标",
      deloyTokenFail: "Token部署失败，请重试",
      notContractAddress: "请提供部署代币的地址",
      wrongStartBlockNum: "起始区块必须大于当前的区块号",
      wrongStopBlockNum: "结束区块必须大于起始区块",
      youHaveCreatedCommunity: "您已经创建了一个社区",
      pleaseFillData: "请选择资产并设置分发策略",
      deployFactoryFail: "合约部署失败，请重试",
      deploySuccess: "部署成功",
      createPoolSuccess: "创建矿池成功",
      updatePoolSuccess: '更新矿池比例成功',
      chargeSuccess: '充值成功',
      needName: "请填写社区名字",
      needWebsit: "请填写社区主页链接",
      needDescription: "请填写社区描述信息",
      needIcon: "请上传社区图标",
      needTokenIcon: "请上传C-token图标",
      needPoster: "请上传社区封面",
      needTokenName: "请输入代币名字",
      needTokenSymbol: "请输入代币符号",
      completeCommunityInfoSuccess: "社区信息更新成功",
      wrongSubstrateAddress: "请输入正确的{type}地址",
      ratioError: "所有质押池的比例和应为100%",
      wrongRewardNum: "请输入正确的奖励数值",
      deployCommunitySuccess: "部署成功!",
      communityNameLimit: "社区名字长度不能超过{count}",
      descriptionLimit: "社区描述字符不能超过{count}",
      needRightUrl: "请输入正确的链接地址",
      withdrawSuccess: "操作成功!",
      harvestSuccess: "收获成功!",
      deploying: "部署合约需要花费一定时间，请耐心等候",
      stopPoolOk: "矿池已经停止",
      removePoolOk: "矿池已移除",
      tryWithdrawOk: "赎回成功",
      stopPoolTips: "矿池分润比例为0才能删除",
      startPoolTips: '矿池已启动',
      completeCommunityProposalConfigSuccess: "提案配置成功",
      completeProposalSuccess: "提案成功",
      completeVoteSuccess: "投票成功",
      deployTokenTip: '注意: 预挖量会一次性挖取到您的账号，这并不代表总的代币发行量，接下来需要您制定详细的分发策略。',
      attention: "注意!",
      markdownTip: "您可以使用Markdown格式来书写",
      stopPoolAttention: '您一旦关闭矿池后，将无法再次开启，请谨慎使用',
      stakeTokenLogoTip: "该图标作为您选择的质押代币{token}的图标使用",
      updateSocialSuccess: '更新成功!',
      startHeightOut: '开始区块高度必须大于当前区块高度T。',
      wrongSocialInfo: "请填写正确的 {type} 链接!",
      noPowerupNut: "没有质押的NUT",
      needAscii: '您只能输入字母',
      npRedeemProcess: '没有赎回中的资产',
      powerDownSuccess: 'Power down成功',
      redeemSuccess: "赎回成功",
      mintableTip1: '创建社区时您设置了c-Token为可挖取的',
      mintableTip2: "现在您必须将该token的挖矿权限授予给社区合约，否则您将不能使用该社区的所有合约功能。",
      delegateTip1: "您现在正在使用 {chain} 的账号: {account} 进行代理。",
      rechargeTip1: "稍后充值",
      feeTip: '操作手续费: ${fee} NUT',
      updatePoolDecFail: '矿池描述更新失败'
    },
    nps: {
      nps: "治理",
      all: "全部",
      pass: "已通过",
      pending: "等待开始",
      unpass: "未通过",
      rolling: "投票中",
      mine: "我发布的",
      title: "标题",
      titleInputTip: "请输入标题",
      startAndEndTime: "开始和结束时间",
      startTime: "开始时间",
      endTime: "结束时间",
      details: "详情",
      detailsInputTip: "请输入提案详细内容。",
      proposalConfigLabel: "提案系统配置",
      voteProposal: "支持提案",
      createProposal: "创建提案",
      noProposals: "没有提案",
      proposalTitle: "标题",
      proposalTitleInput: "请输入提案的标题",
      proposalStart: "开始时间",
      proposalEnd: "结束时间",
      proposalFirst_Block: "开始区块",
      proposalEnd_Block: "结束区块",
      proposalBody: "详情",
      proposalBodyPreview: "预览",
      proposalBodyInput: "请输入详情",
      proposalNetwork: "网络",
      proposalNetworkInput: "请选择网络",
      proposalSymbol: "符号",
      proposalSymbolInput: "请输入符号",
      proposalStrategies: "策略",
      proposalThreshold: "发起阈值",
      proposalThresholdInput: "请输入发起阈值",
      proposalPassThreshold: "通过阈值",
      proposalPassThresholdInput: "请输入通过阈值",
      proposalValidation: "验证",
      proposalValidationInput: "请输入验证",
      proposalAgreeBtn: "赞成",
      proposalDisagreeBtn: "反对",
      propsalSureVote: "您确定吗？",
      propsalVoteRight: "您的投票权重",
      propsalVoteRemind: "您确定 {0} 吗?",
      propsalVoteStatusWaitStart: "等待中",
      propsalVoteStatusDoing: "投票中",
      propsalVoteStatusEnd: "已结束",
      proposalInfo: "提案信息",
      proposalVoteNum: "投票数",
      proposalVoteResult: "投票结果",
      proposalStatus: "投票状态",
      editProposalConfigBtn: "配置提案",
      editStrategy: "编辑策略",
      validationWarning: {
        basic: {
          minScore: "您需要最少{0}个{1}来发起提案。",
        },
        customValidation: "You need to pass the proposal validation in order to submit a proposal.",
      },
      remark: "介绍",
      downloadReport: "下载报告",
      completeContent: "请填写完整的信息",
      endLtStart: '结束区块必须大于开始区块',
      blockExceed: '结束区块必须大于当前区块'
    },
    error: {
      error: "错误",
      depositFail: "质押失败",
      withdrawFail: "取消质押失败",
      changeDepsitFail: "更改质押失败",
      delegateFail: "代理失败",
      changeDelegateFail: "更改代理失败",
      haveCreatedCommunity: "您已经创建过社区了，请刷新重试！",
      approveFail: "授权失败",
      delegateerror: "代理错误",
      needkeychain: "请安装Steem Keychain",
      unlockKeychain: "请解锁Steem Keychain",
      steemLoginFail: "Steem登录失败",
      hiveLoginFail: "Hive登录失败",
      inputError: "输入值错误",
      inputOutOfRange: "请输入 {lowerPnutAmount}-{upperPnutAmount} 的数值",
      inputLinkIllegal: "请输入正确的链接",
      notEnoughFee: "手续费不足",
      insufficentPnut: "PNUT不足",
      insufficentErc20: "{symbol}余额不足",
      hasVoted: "nutbox.mine已经点赞了您的帖子",
      pleaseRetry: "请重试",
      signatureFailed: "签名失败，请刷新页面后重试",
      serveError: "服务端错误",
      networkError: "网络错误",
      noStakingFactory: "您还没有创建社区",
      blockChainError: "合约调用失败",
      cancelSigning: "取消签名",
      unknow: "未知错误",
      notConnectWallet: "未连接钱包",
      unlockWallet: "请解锁钱包",
      wrongChainId: "错误的链ID",
      wrongETHAddress: "错误的ETH地址",
      wrongInputDevRatio: "请输入0-100的数字",
      notTokenContract: "您输入的地址不是一个BEP20地址",
      transactionFail: "交易失败!",
      steemAccountActiveKeyEmpty: "Steem账号缺失或者活跃秘钥缺失",
      hiveAccountEmpty: "Hive账号缺失",
      hiveAccountActiveKeyEmpty: "Hive账号缺失或者活跃秘钥缺失",
      wrongConfirmInfo: "确认信息错误!"
    },
    router: {
      information: "基础信息",
      profile: "个人页面",
      asset: "资产",
      pool: "矿池",
      social: "社交资料",
      governance: "治理",
      staking: "质押",
      blog: "博客",
      member: "成员列表",
      home: '主页',
      farming: '耕种',
      nftStake: 'NFT质押',
      nftStaking: 'NFT质押',
      nutPower: 'NUT Power',
      curation: '社交矿池'
    },
    placeHolder: {
      inputName: "请输入社区名字",
      inputLink: "请输入社区网页链接",
      inputDesc: "请输入社区描述信息",
      inputStartBlock: "请输入开始区块",
      inputStopBlock: "请输入结束区块",
      inputMintAmount: "请输入每区块数量",
      inputDevRatio: "请输入DAO基金比例",
      remarkInput: "请输入描述",
      confirmInfo: '请输入"{info}"并确认',
      inputPoolName: '请输入矿池名字',
      inputDelegatee: '请输入您的代理账号',
      inputDevAddress: '请输入新的DAO基金地址',
      inputRecipientAddress: '请输入接收地址',
      inputPoolDesc: '请输入该矿池的详细描述'
    },
    community: {
      community: "社区",
      social: "社交信息",
      communityList: "社区列表",
      communityTokenList: "C-Token列表",
      communityName: "名字",
      communityLink: "官网链接",
      communityDesc: "描述",
      communityLogo: "图标",
      cTokenLogo: "C-Token图标",
      communityPoster: "封面",
      communityBalance: "社区余额",
      communityToken: "社区代币",
      communityAsset: '社区资产',
      fundAddress: "基金地址",
      fundRatio: "基金比例",
      createCommunity: '创建社区',
      manageCommunity: '管理社区',
      communityDashboard: "仪表盘",
      communtiyDesc: "轻而易举地将DeFi，社交媒体和治理模块添加到社区。",
      totalDeposit: '总量',
      currentBlock: '当前区块',
      joinedCommunity: '加入的社区',
      noJoinedCommunity: '还没有加入的社区',
      noJoinedPool: "还没有参与的矿池",
      exploreCommunity: '探索社区',
      retainedRevenue: '保留的资金',
      insufficientBalance: "社区余额不足",
      member: '成员',
      daoFundInfo: 'DAO基金信息',
      daoTreasuryInfo: 'DAO国库信息',
      choseToken: `您选择了<span class="text-primary-0 font20"> {symbol} </span>作为社区代币。`,
      choseTokenAsCtoken: '选择一个代币作为您的社区代币',
      needTokenToCreate: `在分发社区代币前，需要充值足够的
      <span class="text-primary-0 font20"> {symbol} </span>
      到社区`,
      chargeTip1: "您可以现在充值, 或者后续在社区管理页面充值",
      noCommunity: "您还没有创建社区",
      choseAsset: '选择资产',
      deployAsset: '部署社区资产',
      completeInfo: '完善社区信息',
  
      startBlock: "开始区块",
      stopBlock: "停止区块",
      mintAmount: "每区块产量",
      preMintTitle: "预挖",
    },
    pool: {
      updatePoolRatios: "配置矿池",
      addPool: "添加矿池",
      chosePoolType: '选择矿池类型',
      pools: '矿池',
      tokenDistribution: "代币分配",
      recipient: '接收者',
      poolName: '矿池名字',
      noPools: '没有创建的矿池',
      userCount: '用户数量',
      totalDeposit: '总质押量',
      totalStaked: '总质押量',
      totalDelegated: '总代理量',
      stakedPools: '质押矿池',
      tvl: 'TVL',
      closePool: "关闭",
      startPool: "启动",
      ratio: '比例',
      opened: '活跃的',
      closed: '已关闭',
      activePools: '活跃矿池',
      inactivePools: '关闭矿池',
      creatPool: '在{chainName}创建质押矿池',
      createCurationPool: '在{chainName}创建社交奖励池',
      createDelegatePool: '创建 {delegateType} 代理矿池',
      createNftPool: '在Create staking NFT(ERC1155) pool on {chainName创建质押NFT(NFT1155)矿池',
      yourDelegatee: '您的 {delegateType} 代理账号',
      ratioTip: '调整矿池比例(矿池比例之和应为100%)',
      poolDescTitle: '矿池详细描述',
      poolDescTip: '你可以尽量详细的描述该矿池，比如接收地址的详细描述，奖励将如何分配到用户手上等等。',
      recipientDescription: '社交矿池会根据您配置的矿池比例来计算应该分得的奖励数量。该矿池的所有收入都会由设置的接收地址接收，该地址可以是一个EOA，也可以是一个合约地址。 这个可以使社区代币的分发更加灵活，比如可以将该地址设置为一个社交分发协议的合约，则该矿池的产品将分发到该社交协议进行二次分发。另外一点，矿池的产出将全部由该地址接收，直到您将其修改，所以矿池产出的奖励可以被任何人执行领取操作。',
      poolDescription: '这个描述信息将展示在该矿池的卡片上，用户将从卡片上的描述了解到该矿池的作用，所以请尽可能的描述清楚该矿池的详细信息。',
      harvestCurationPoolTip: "任何人都可以收取该矿池的奖励到接受者地址。",
      recipientTip: "接收者地址可以是一个EOA，也可以是一个合约地址，其作用是对矿池的奖励做更复杂的二次分发。"
    },
    np: {
      nutPower: 'NUT Power（NP）',
      npDesc: "Nutbox Voting Power(简称NUT Power)代表Nutbox DAO的治理权利. 用户可根据治理活动获取相应的治理收益。",
      claimNut: '申领NUT',
      claimNp: '申领NP',
      claimAvailable: '可申领：',
      availabeClaimNut: '{amount} Nut可以申领。',
      addNpPool: "添加一个NP投票池",
      npTip1: "您需要选择一个活跃的矿池来创建对应的NP投票池. 用户可以通过NP投票该池子来获取c-Token和NUT收益",
      npTip2: "* NUT是由Nutbox合约分发, c-token是来自于对应的矿池的佣金",
      npTip3: "可用NP: 可以用来投票或者赎回NUT的部分。",
      npTip4: '已投票NP: 所有您在Nutbox已投票的NP。',
      npTip5: '正在解锁的NP: 您Power down后，还在解锁期的NP。',
      voteFor: '投票',
      claimbleNut: '可申领NUT',
      claimedNut: '赎回的NUT:',
      totalNp: '总NP',
      totalNpTip: '总NP: 所有您拥有的NP。',
      powerDown: 'Power down',
      powerDownTip: 'Power down NP 为 NUT',
      powerDownTip2: '{selectRatio} 周 | 每{selectRatio}个NP赎回 1 NUT',
      powerUpTip1: '升级 NP 解锁时间从 {srcPeriod} 周到 {distPeriod} 周。',
      powerUpTip2: 'Power up 每 1 NUT到 {distPeriod} NP',
      powerUpTip3: '根据您选择的解锁时间, 您可以power up 1 NUT到1-64不等的NP.',
      powerUpTip4: `您已经power up {nut} NUT 为 {np} NP. 现在您可以通过选择下列一个方块升级解锁期以得到更多的NP。注意，对应的解锁周期将延长。`,
      powerUpTip5: '1 NUT对应 {ratio} NP',
      powerUpTip6: '{period} NP 到 {ratio} NP',
      powerUpTip7: '解锁时间: {unlockTime} 周',
      nutStaked: "质押的NUT:",
      npAvailable: '可用的NP:',
      npVoted: '已投票的NP:',
      unlockPeriod: '解锁时间:',
      unlockingNp: '解锁中的NP',
      unlockingPeriod: '解锁时间',
      unlockInWeeks: '{period} 周解锁时间：',
      covertTip: '{npAmount} NP 到 {nutAmount} NUT',
      daysLeft: '天',
      hoursLeft: '小时',
      minutesLeft: '分钟',
      secondsLeft: '秒',
      upgradeTip1: `您可以通过power down操作来将NP赎回为NUT。该操作会花
      <strong class="text-primary-1"> {distPeriod} 周</strong>
      来线性释放您的NUT。`
    },
    gauge: {
      voterCount: '投票者',
      totalVoted: '总投票',
      vote: "投票",
      unvote: "减少投票"
    },
    asset: {
      tokenName: "名字",
      tokenSymbol: "符号",
      tokenAddress: "代币地址",
      tokenId: "代币ID",
      wrongTokenId: "代币ID必须是整数",
      price: "价格",
      totalSupply: '供应量',
      cap: '市值',
      distributionAmount: "预挖量",
      notErc1155: "这不是一个ERC1155代币。",
      wrongAddress: '地址错误',
      choseCToken: '选择一个代币作为社区代币',
      setDistr: '设置资产的分发策略',
      totalDistr: '当前分发策略可分发总量',
      currentBlock: '当前区块高度',
      tip1: '您选择了开发挖取功能的代币',
      tip2: '为了社区管理方便，你需要将代币的挖取权限转移给社区。',
      tip3: '需要注意的是一旦移交权限给社区合约，将无法转移回去了。',
      tip4: "如果您不了解其中的机制，不勾选复选框即可",
      promiseGrant: '好的，我会将权限转移给社区。',
      tip5: '您的社区合约部署成功!',
      tip6: '请确认将代币挖取权限转移给社区合约。'
    },
    treasury: {
      treasuryAddress: "国库地址",
      daoTreasury: "DAO国库",
      treasuryAsset: "国库资产",
      noCreatedTip: "您可以创建您社区的国库",
      createNote: "备注: 一旦您创建了社区国库, 任何人都可以使用c-Token从国库赎回对应份额的资产。"
    },
    opHistory: {
      deposit: "质押了 {amount} {symbol} 到 {pool}",
      depositWithName: "质押了 {amount} {symbol} 到 {pool}",
      add: "从矿池{pool}添加 {amount} {symbol} 给 {delegatee}",
      addWithName: "从矿池{pool}添加 {amount} {symbol} 到 {delegatee}",
      withdraw: "从 {pool} 减少 {amount} {symbol}",
      withdrawWithName: "从 {pool} 减少 {amount} {symbol}",
      sub: "从 {pool} 减少 {amount} {symbol} 给 {delegatee}",
      subWithName: "从 {pool} 减少 {amount} {symbol} 给 {delegatee}",
      vote: "投票 {amount} {symbol} 给 {pool}",
      voteWithName: "投票 {amount} {symbol} 给 {pool}",
      unvote: "减少投票 {amount} {symbol} 给 {pool}",
      unvoteWithName: "减少投票 {amount} {symbol} 给 {pool}",
      harvestGauge: "从{pool}收取{amount} {symbol}",
      harvestGaugeWithName: "从{pool}收取{amount} {symbol",
      createGauge: '为{pool}创建一个投票池',
      createGaugeWithName: '为{pool}创建一个投票池',
      withdrawGauge: "从计量池收取 {amount} NUT",
      withdrawGaugeWithName: "从计量池收取 {amount} NUT",
      harvest: "从 {pool} 收取 {amount} {symbol} 奖励",
      harvestWithName: "从 {pool} 收取 {amount} {symbol} 奖励",
      harvestAll: "收取所有的{symbol}",
      harvestAllWithName: "收取所有的{symbol}",
      redeemTreasury: "燃烧{amount} {symbol}从国库赎回奖励",
      redeemTreasuryWithName: "燃烧{amount} {symbol}从国库赎回奖励",
      create: "创建社区",
      createWithName: "创建社区",
      setFee: "调整DAO基金比例为：{amount}%",
      setFeeWithName: "调整DAO基金比例为：{amount}%",
      addPool: '创建了新矿池: {pool}',
      addPoolWithName: '创建了新矿池: {pool}',
      closePool: "关闭了矿池: {pool}",
      closePoolWithName: "关闭了矿池: {pool}",
      setRatio: "调整矿池比例",
      setRatioWithName: "调整了矿池比例",
      setDaofund: "修改DAO基金地址为: {address}",
      setDaofundWithName: "修改DAO基金地址为: {address}",
      withdrawRevenue: "提取国库资金到{address}",
      withdrawRevenueWithName: "提取国库资金到{address}",
      createTreasury: "创建国库: {address}",
      createTreasuryWithName: "创建了国库: {address}",
      changeCurationPoolRecipient: "将矿池：{pool}的接收者修改为了 {address}",
      startCurationPool: "启动社交矿池: {pool}",
      startCurationPoolWithName: "启动社交矿池: {pool}",
      title: {
        DEPOSIT: "质押",
        WITHDRAW: '减少质押',
        VOTE: "投票矿池",
        UNVOTE: "减少投票矿池",
        WITHDRAWGAUGENUT: "收取NUT奖励",
        ADMINCREATENEWGAUGE: "创建投票池",
        WITHDRAWGAUGECTOKEN: "收取c-Token奖励",
        ADMINWITHDRAWGAUGENUT: "收取NUT奖励",
        HARVEST: "收获",
        HARVESTALL: "收获全部",
        REDEEMFROMTREASURY: "赎回国库资产",
        ADMINCREATE: "创建社区",
        ADMINSETFEE: "设置DAO基金比例",
        ADMINADDPOOL: '创建新矿池',
        ADMINCLOSEPOOL: "关闭矿池",
        ADMINSETRATIO: "重设矿池比例",
        ADMINSETDAOFUND: "重设DAO基金",
        ADMINWITHDRAWNREVENUE: "国库提款",
        ADMINCREATETREASURY: "创建国库",
        ADMINCHANGECURATIONRECIPIENT: "变更接受者",
        STARTCURATIONGAUGE: "启动社交矿池"
      }
    }
  };