module.exports = {
  commen: {
    steemAccoutPlaceHolder: "Steem Account",
    hiveAccountPlaceHolder: "Hive account",
    loginByKeychain: "Login by Keychain",
    loging: "Logging in...",
    crowdstaking: "Staking",
    optional: "(Optional)",
    connectMetamask: 'Connect Metamask',
    delegateFee: 'Delegate fee',

    apy: "APR",
    balance: "Balance",
    max: "Max",
    complete: "Complete",
    now: "Now",
    poster: "Poster",
    more: 'More',
    detail:'Detail',
    activities: 'Activities',
    administor: 'Administor',
    joinDate: 'Join Date',
    noData: 'No Data',
    pageNotFound: "Page Not Found!",
    backHome: "Back to Home",
    amount: "Amount",
    earned: 'EARNED',
    voted: "VOTED",
    staked: "STAKED",
    delegated: "DELEGATED",
    totalStake: 'Total Staking',
    voters: 'Voters',
    stakers: 'Stakers',
    summary: 'Summary',
    weeks: 'weeks',
    or: 'OR',

    whitePaper: "White Paper",
    nps: "Nutbox Proposal System",
    contract: "Contract",
    social: "Social info",
    docs: "Docs",
    discord: "Discord",
    blog: "Blog",
    contractAddr: "Contract Address",
    dashboard: "Dashboard",
    auditReport: "Audit Report",
    language: "Language",
    zh: "中文",
    en: "English",
    kr: "한국어",
    my: "Malaysia",
    tr: "Türkçe",
    es: "Español",
    jp: "日本語",
    goToHome: "Back to homepage",
    home: 'Home',
  },
  desc: {
    slogan: 'Create Your Staking Economy In Minutes',
    featuredCommunity: 'Featured Communities',
    aboutNutbox: 'Abount Nutbox',
    totalValue: 'Total Asset Value',
    disStrategy: 'Mint Strategy',
    adminOnly: 'Admin only',
    youWillReceive: 'You will receive: ',
    iso: 'ISO: Initial Staking Offering',
    isoDetail: 'Stake PoS Token mint community Token. High APR, No risk.',
    erc1155Desc: 'Stake your ERC1155 token to earn. So makking good use of this mining pool can provide more flexible allocation of community token.',
    curation: 'Social pools can directly distribute rewards to any address inclued contract. And everyone can excute the harvet operation, all the reward will send to the recipient after that.',
    nutPower: 'Nut power is the governance right of Nutbox DAO. it is generated by staking NUT, the longer NUTs are locked, the more Nut Power will be generated. It is also a core factor to determine the distribution of new NUTs.',
    farming: 'Just stake some tokens to earn.',
    assetTip1: 'If you want to use an exist token',
    assetTip2: 'If you want to create a new token',
    assetTip3: `*You can premint some tokens to bootup your community,
    the rest tokens will be minted from the contract block by block.`
  },
  operation: {
    withdraw: "Withdraw",
    harvest: "Harvest",
    approveContract: "Approve Contract",
    approveCommunity: 'Approve Community',
    approvePool: 'Approve Pool',
    connectMetamask: "Connect Metamask",
    change: 'Change',
    search: "Search",
    userlogin: "Login",
    uploadLogo: "Upload Logo",
    uploadPoster: "Upload Poster",
    sign: "Sign and upload",
    hide: 'Hide',
    cancel: "Cancel",
    commit: "Commit",
    complete: "Complete",
    confirm: "Confirm",
    create: "Create",
    add: "Add",
    update: "Update",
    delegatecharge: "Delegate Charge",
    login: "Login",
    logout: "Log out",
    input: "Input",
    ok: 'OK',
    copy: "Copy",
    edit: "Edit",
    charge: 'Charge',
    redeem: "Redeem",
    enter: 'Enter',
    approve: 'Approve',
    grant: 'Grant',
    manageCommunity: "Manage your community",
    exploreCommunity:'Explore Communities',
    save: 'Save',
    grantMintRole: 'Grant mint role',
    configurePoolRatio: 'Configure Delegate Pool',
    addDelegatePool: 'Add Delegate Pool',
    addPool: 'Add Pool',
    claim: 'Claim',
    recharge: "Recharge",
    upgrade: 'Upgrade',
    choseToken: "Choose Token",
    registerAsset: "Register an asset",
    deploy: 'Deploy'
  },
  wallet: {
    wallet: "Wallet",
    connectSteem: "Connect Steem Wallet",
    connectHive: "Connect Hive Wallet",
    connectCosmos: "Connect Keplr Wallet",
    install: "Install",
    transfer: "Transfer",
    asset: "Asset",
    nomination: "Vote Delegators ",
    contribution: "Crowdloan",
    balance: "balance",
    bond: "Bonding",
    unBond: "Unbonding",
    redeem: "Redeem",
    bindAmount: "Bonded Amount",
    inputAmount: "Input Amount",
    receiveAddress: "Receiver Address",
    transferAmount: "Transfer Amount",
    unbindAmount: "Unbonding amount",
    intallKeplr: "Please install keplr"
  },
  date: {
    year: " Year ",
    month: " Month ",
    day: " Day ",
    hour: " Hour ",
    min: " Minute ",
    sec: " Second ",
  },
  transaction: {
    broadcasting: "Transaction Is Broadcasting.",
    inBlock: "Transaction included at blockHash.",
    contributeOk: "Contribution Success!",
    withdrawOk: "Withdraw Success!",
    nominateOk: "Nominate Success!",
    transactionOk: "Transaction Success!",
    depositOk: "Deposit Success",
    withdrawOk: "Decrease Deposit Success!",
    voteOk: 'Vote Success',
    unvoteOk: "Unvote Success",
    
  },
  stake: {
    stake: "Staking Mining",
    creaseDelegation: "Delegate",
    increaseDelegation: "Undelegate",
    getSp: "Get SP",
    get: "Get",
    stake: "Deposit",
    unStake: "Undeposit",
    staked: "Staked ",
    changeAccount: 'Please change {type} Account',
    changeAccountHtml: `Please change
    <span class="text-primary-0 font-bold">{type}</span>
    Account`,
    changeAddress: 'Please change {chainName} address',
    changeAddressHtml: `Please change
    <span class="text-primary-0 font-bold"> {chainName} </span>address`,
    accountMismatch1: `Your {type} account haven't binding with current {chainName} address, please
    change {type} account in your wallet first.`,
    accountMismatch2: `Your {chainName} address haven't binding with current {type} account, please
    change {chainName} address in your wallet first.`,
    accountMismatch3: `Your
    <span class="text-primary-0 font-bold">{type}</span>
    account haven't binding with current
    <span class="text-primary-0 font-bold">{chainName}</span>
    address, please change
    <span class="text-primary-0 font-bold">{type}</span>
    account in your wallet first.`,
    accountMismatch4: ` Your <span class="text-primary-0 font-bold"> {chainName} </span>
    address haven't binding with current
    <span class="text-primary-0 font-bold"> {type}
    </span>
    account, please change
    <span class="text-primary-0 font-bold"> {chainName} </span>
    address in your wallet first.`,
    bindAccountTip1: `Your binding {type} account is:`,
    bindAddressTip1: 'Your binding address is:'
  },
  tip: {
    approveSuccess: "Approval Success",
    loading: "Loading, Please wait",
    connectingPolkadot: "Connecting Polkadot network",
    noAuction: "No ongoing Auction ",
    noProject: "No ongoing Project",
    noNominations: "No Nominators Nodes",
    noContribuitons: "No Contribution Records",
    noCommunities: "No Registered Communities ",
    noSearchedToken: "No Searched Token",
    noCrowdloan: "No ongoing Crowdloan",
    noStakingProject: "No validators",
    picTip: "The recommended size is {size} ，smaller than 2M, support jpg、png、jpeg formats",
    tokenSafeTip: "Your asset would be locked into {symbol} official crowdloan pallet directly, not any accounts of Nutobx.",
    belowMinContribution: "Input is less than the minimum required contribution of {min}",
    belowMinBond: "Input is less than the minimum suggested bond amount of 0.1",
    wrongNominatorAddress: "Wrong Nominator Address",
    wrongTransferAddress: "Wrong Transfer Address",
    inputAddressType: "Please Input {relaychain} Address",
    insufficientBalance: "Insufficient Balance",
    editTip: "Uploading community information requires the use of your wallet signature, this operation will not cost you anything, please rest assured to use。",
    outOfCap: "Out of cap",
    tips: "Tips",
    error: "Error！",
    success: "Success!",
    warning: "Warning！",
    noNeedWithdraw: "No Need To Withdraw",
    comingSoon: "Coming Soon",
    clipboard: "Clipboard",
    copyAddress: "Copied address:  {address}",
    signing: "Signing",
    picUploadFail: "Pictures fail to upload, Please try again",
    largeImg: 'Image size exceeds 2M',
    outOfUsage: 'Upload so frequently, please try later.',
    uploadLogo: "Please upload logo",
    deloyTokenFail: "Token fail to deloy, Please try again",
    notContractAddress: "Please Provide Deploy Token Contract Address",
    // registryAssetFail: "Registry Asset Fail",
    wrongStartBlockNum: "Start BlockNum must be larger than the current BlockNum",
    wrongStopBlockNum: "Stop BlcokNum must be larger than the Start BlockNum",
    youHaveCreatedCommunity: "You Have Created Community",
    pleaseFillData: "Please select delpoy asset and distribution policies",
    deployFactoryFail: "Factory Fail to delpoy, Please try agian",
    deploySuccess: "Deloy Success",
    createPoolSuccess: "Create pool success",
    updatePoolSuccess: 'Update pool ratios success',
    chargeSuccess: 'Charge success',
    // storeTokenAddress: "Please confirm the information and store Token Address immediately",
    needName: "Please input Community Name",
    needWebsit: "Please input Community Website",
    needDescription: "Please input Community Description",
    needIcon: "Please upload Community Logo",
    needTokenIcon: "Please upload C-token Logo",
    needPoster: "Please Upload Community Poster",
    needTokenName: "Please input token name",
    needTokenSymbol: "Please input token symbol",
    completeCommunityInfoSuccess: "Community Information update successfully",
    wrongSubstrateAddress: "Please input correct {type} address",
    ratioError: "The total proportion of Staking Pool is 100",
    // wrongApy: "Please input correct APR",
    wrongRewardNum: "Please input correct rewards",
    deployCommunitySuccess: "Deploy success!",
    communityNameLimit: "The community name cannot exceed {count} word",
    descriptionLimit: "The community description cannot exceed {count} words",
    needRightUrl: "Please input right url.",
    // selectCowdloan: "Please select a crowdloan project.",
    inputRightBlogTag: "Please input a right Steem community tag. eg:hive-12345.",
    withdrawSuccess: "Withdraw success!",
    harvestSuccess: "Harvest success!",
    deploying: "Deploying, may take some time, please wait for a moment.",
    stopPoolOk: "Pool has been stopped",
    removePoolOk: "Pool has been removed",
    tryWithdrawOk: "Redeem success.",
    stopPoolTips: "Need set pool ratio to 0 before stop pool.",
    startPoolTips: "Pool has been started",
    completeCommunityProposalConfigSuccess: "Community proposal config success",
    completeProposalSuccess: "Proposal success",
    completeVoteSuccess: "Vote success",
    // needGameName: "Please input game name",
    // gameNameLimit: "The game name cannot exceed{count}words",
    // needGameType: "Please choose game type",
    // completeGameSuccess: "Game Success",
    deployTokenTip: 'Attention: this is the pre-distribution amount mint to your own account, not total supply. Please be carefull.',
    attention: "Attention!",
    markdownTip: "You can use markdown format.",
    stopPoolAttention: 'Once you close the pool, it cannot be opened again. Please use this function with caution!',
    stakeTokenLogoTip: "This logo is set to the stake token {token} which you selected.",
    updateSocialSuccess: 'Update successful!',
    startHeightOut: 'The first start block must bigger than current block.',
    wrongSocialInfo: "Please fill right {type} link!",
    noPowerupNut: "Haven't power up NUT",
    needAscii: 'You can only input simple characters.',
    npRedeemProcess: 'No redeem process.',
    powerDownSuccess: 'Power down success',
    redeemSuccess: "Redeem Successfull",
    mintableTip1: 'You have set c-Token mintable when you create community.',
    mintableTip2: "Now you must grant mint role to the community contract, otherwise you can't use functions of this community.",
    delegateTip1: "You're using {chain} account: {account} to delegate.",
    rechargeTip1: "Recharge Later",
    feeTip: 'Operation fee: ${fee} NUT',
    updatePoolDecFail: 'Update pool description fail!'
  },
  nps: {
    nps: "Governance",
    // npsTemp: "Show Peanut governance for example. Walnut governance is under development.",
    all: "All",
    pass: "Passed",
    pending: "Pending",
    unpass: "Rejected",
    rolling: "Voting",
    mine: "My publish",
    title: "Title",
    titleInputTip: "Please input title",
    startAndEndTime: "Start & end time",
    startTime: "Start time",
    endTime: "End time",
    details: "Details",
    detailsInputTip: "Please input proposal details.",
    proposalConfigLabel: "Proposal Config",
    voteProposal: "Vote Proposal",
    createProposal: "Create Proposal",
    noProposals: "No proposals",
    proposalTitle: "Title",
    proposalTitleInput: "Please input proposal title",
    proposalStart: "Start time",
    proposalEnd: "End time",
    proposalFirst_Block: "Start block",
    proposalEnd_Block: "Stop block",
    proposalBody: "Detail",
    proposalBodyPreview: "Preview",
    proposalBodyInput: "Please input detail",
    proposalNetwork: "Network",
    proposalNetworkInput: "Please select Network",
    proposalSymbol: "Symbol",
    proposalSymbolInput: "Please input symbol",
    proposalStrategies: "Strategies",
    proposalThreshold: "Threshold",
    proposalThresholdInput: "Please input threshold",
    proposalPassThreshold: "Pass threshold",
    proposalPassThresholdInput: "Please input pass threshold",
    proposalValidation: "Validation",
    proposalValidationInput: "Please input validation",
    proposalAgreeBtn: "Agree",
    proposalDisagreeBtn: "Disagree",
    propsalSureVote: "Are you sure",
    propsalVoteRight: "Your voting rights",
    propsalVoteRemind: "Are you sure {0}?",
    propsalVoteStatusWaitStart: "Wait",
    propsalVoteStatusDoing: "Voting",
    propsalVoteStatusEnd: "End",
    proposalInfo: "Proposal Info",
    proposalVoteNum: "Vote Number",
    proposalVoteResult: "Vote Result",
    proposalStatus: "Vote status",
    editProposalConfigBtn: "ProposalConfig",
    editStrategy: "Edit Strategy",
    validationWarning: {
      basic: {
        minScore: "You need to have a minimum of {0} {1} in order to submit a proposal.",
      },
      customValidation: "You need to pass the proposal validation in order to submit a proposal.",
    },
    remark: "Intro",
    downloadReport: "Download Report",
    completeContent: "Please fill all the blanks.",
    endLtStart: 'End block number must greater than start block',
    blockExceed: 'End block number must greater than current block number'
  },
  error: {
    error: "Error",
    depositFail: "Deposit Fail",
    withdrawFail: "Withdraw Fail",
    changeDepsitFail: "Change deposition fail",
    delegateFail: "Delegate fail",
    changeDelegateFail: "Change delegation fail",
    haveCreatedCommunity: "You already created a community. Please refresh for try!",
    approveFail: "Approve fail",
    accountChanged: "Steem and Tron address mismatch，Please confirm whether the wallet account is switched",
    delegateerror: "Delegation Error！",
    tryrefreshpage: "Error，please refresh page and try again！",
    needkeychainandtronlink: "Need to install Steem Keychain and TronLink！",
    needkeychain: "Please install Steem Keychain",
    needtronlink: "Please install TronLink",
    // insufficientEnerge: "Not enough energy",
    transferFail: "Transfer Fail",
    unlockKeychain: "Please unlock Steem Keychain",
    // unlockWallet: "Plase unlock tronlink",
    steemLoginFail: "Steem login fail",
    hiveLoginFail: "Hive login fail",
    // illegalTronAddress: "Invalid tron address",
    inputError: "Input wrong value",
    inputOverflow: "Input overflow",
    inputOutOfRange: "Please input value between {lowerPnutAmount}-{upperPnutAmount}",
    inputLinkIllegal: "Please input correct link",
    notEnoughFee: "Insufficient fee",
    insufficentPnut: "Insufficient PNUT",
    insufficentErc20: "Insufficient {symbol}",
    hasVoted: "Your post has been voted by nutbox.mine",
    pleaseRetry: "Please try agian",
    signatureFailed: "Signature fail，Please refresh the page and retry",
    serveError: "Server Error",
    networkError: "Network Error",
    noStakingFactory: "You have not created a community yet",
    blockChainError: "Contract invocation error",
    cancelSigning: "Signing cancel",
    unknow: "Unkonwn error",
    notConnectWallet: "Wallet not Connect",
    unlockWallet: "Please unlock Wallet",
    wrongChainId: "Wrong ChainID",
    wrongETHAddress: "Wrong ETH Address",
    wrongInputDevRatio: "Please input number between 0-100",
    notTokenContract: "The address you input is not a BEP20 contract address.",
    transactionFail: "Transaction fail!",
    assetHasRegisterd: "Asset has been registered by someone.",
    steemAccountEmpty: "Steem account missing",
    steemAccountActiveKeyEmpty: "Steem account or active key missing",
    hiveAccountEmpty: "Hive account missing",
    hiveAccountActiveKeyEmpty: "Hive account or active key missing",
    // tokenDeploying: "There's token in deploying, please wait or refresh page to try.",
    wrongConfirmInfo: "Wrong confirm info!"
  },
  // navigator
  router: {
    information: "Information",
    profile: "Profile",
    asset: "Asset",
    pool: "Pool",
    social: "Social Info",
    governance: "Governance",
    staking: "Staking",
    blog: "Blog",
    member: "Member",
    home: 'Home',
    farming: 'Farming',
    nftStake: 'NFT Stake',
    nftStaking: 'NFT Staking',
    nutPower: 'NUT Power',
    curation: 'Social pool'
  },
  // placeHolder tip
  placeHolder: {
    inputName: "Please input Community Name",
    inputLink: "Please input Community Website Link",
    inputDesc: "Plese input Community Description ",
    inputApy: "Please input Apr",
    inputStartBlock: "Please input start block",
    inputStopBlock: "Please input stop block",
    inputMintAmount: "Please input mint number",
    inputDevRatio: "Please input DAO fund ratio",
    remarkInput: "Please input introduce",
    confirmInfo: 'Please input "{info}" to confirm.',
    inputPoolName: 'Please input pool name.',
    inputDelegatee: 'Please input delegatee account',
    inputDevAddress: 'Please input new DAO fund address',
    inputRecipientAddress: 'Please input recipient address',
    inputPoolDesc: 'Please input the description of this pool'
  },
  community: {
    community: "Community",
    social: "Social",
    communityList: "Community List",
    communityTokenList: "C-Token List",
    communityName: "Name",
    communityLink: "Offcial Link",
    communityDesc: "Description",
    communityLogo: "Logo",
    cTokenLogo: "C-Token Logo",
    communityThemeColor: "Theme color",
    communityPoster: "Poster",
    communityBalance: "Community Balance",
    communityToken: "Community Token",
    communityAsset: 'Community Asset',
    createCommunity: 'Create Community',
    manageCommunity: 'Manage Community',
    communityDashboard: "Community Dashboard",
    communtiyDesc: "It's easy to bring DeFi, social media and Governance to the community",
    fundAddress: "Fund Address",
    fundRatio: "Fund Ratio",
    totalDeposit: 'Total amount',
    currentBlock: 'Current block',
    joinedCommunity: 'Joined Communities',
    noJoinedCommunity: 'No joined communities',
    noJoinedPool: "No joined pools",
    exploreCommunity: 'Explore Communities',
    retainedRevenue: 'Retained Revenue',
    insufficientBalance: "Insufficient Community Balance",
    member: 'Member',
    daoFundInfo: 'Dao Fund Info',
    daoTreasuryInfo: 'Dao Treasury Info',
    choseToken: `You have choose <span class="text-primary-0 font20"> {symbol} </span> as community token.`,
    choseTokenAsCtoken: 'Choose a token as cToken',
    needTokenToCreate: `Before distribution, you need deposit enough
    <span class="text-primary-0 font20"> {symbol} </span>
    to the community`,
    chargeTip1: "You can do it now, or later in the community management page.",
    noCommunity: "You have no community yet",
    choseAsset: 'Chose an asset',
    deployAsset: 'Deploy community asset',
    completeInfo: 'Complete info',

    startBlock: "Start block",
    stopBlock: "Stop block",
    mintAmount: "Token mint/block",
    preMintTitle: "Premint",

    opened: 'Opened',
    closed: 'Closed'
  },
  pool: {
    updatePoolRatios: "Configure Pools",
    addPool: "Add Pool",
    chosePoolType: 'Choose a pool type',
    pools: 'Pools',
    tokenDistribution: "Token allocation",
    recipient: 'Recipient',
    poolName: 'Pool Name',
    noPools: 'No pool created',
    userCount: 'User count',
    totalDeposit: 'Total deposit',
    totalStaked: 'Total Staked',
    totalDelegated: 'Total Delegated',
    stakedPools: 'Staked Pools',
    tvl: 'TVL',
    closePool: "Close",
    startPool: 'StartPool',
    ratio: 'Ratio',
    opened: 'Active',
    closed: 'Closed',
    activePools: 'Acitive Pools',
    inactivePools: 'Inacitive Pools',
    creatPool: 'Create staking pool on {chainName}',
    createCurationPool: 'Create social pool on {chainName}',
    createDelegatePool: 'Create {delegateType} delegate pool',
    createNftPool: 'Create staking NFT(ERC1155) pool on {chainName}',
    yourDelegatee: 'Your {delegateType} delegatee account',
    ratioTip: 'Profit Sharing Ratio (Sum of ratios should be 100%)',
    poolDescTitle: "Pool description",
    poolDescTip: 'You can descripte this pool in detail as much as possible. Like the recipient address, the reward use of the recipient and so on.',
    recipientDescription: 'Social pool can calculate the amount of C-Token with the pool ratios, and the rewards are totally distributed to this address that can be a EOA or contract. This make the token distribution more flexible. For example, you can set the recipient address to a social distribution contract, then the rewards amount from nutbox will be claimed from this pool to your social pool. Additional, the address is always be the one until community manager change it. So the rewards mining from this pool can be claimed by everyone.',
    poolDescription: 'This description will be displayed on the card of the mining pool, and users can learn about the role of the mining pool from this description. Please describe the role of this mining pool as clearly as possible.',
    harvestCurationPoolTip: "Everyone can harvest this pool rewards to the recipient address.",
    recipientTip: "The recipient address can be a EOA or contract address that use to secondary distribute the rewards。"
  },
  np: {
    nutPower: 'NUT Power（NP）',
    npDesc: "Nutbox Voting Power(NUT Power for short) is the Governance Power of Nutbox DAO. The Nutbox governance reward of a user is charged by the user's governance activity.",
    claimNut: 'Claim NUT',
    claimNp: 'Claim NP',
    claimAvailable: 'Claim available：',
    availabeClaimNut: '{amount} Nut is available to claim.',
    addNpPool: "Add a NP vote pool",
    npTip1: "In order to add a NUT Power vote pool, you need choose an existing staking pool. Users can earn NUT and c-token from NP vote pool.",
    npTip2: "* NUT is  issued by Nutbox contract, c-token is from staking pool's commission.",
    npTip3: "Available NP: NP could be used to vote or unlocking.",
    npTip4: 'Voted NP: NP you voted into pools of entire Walnut.',
    npTip5: 'Unlocking NP: NP you powered down, in the unlocking process.',
    voteFor: 'Vote for',
    claimbleNut: 'Claimble NUT',
    claimedNut: 'Claimed NUT:',
    totalNp: 'Total NP',
    totalNpTip: 'Total NP: All NP you owned.',
    powerDown: 'Power down',
    powerDownTip: 'Power down NP to NUT',
    powerDownTip2: '{selectRatio} weeks | every {selectRatio} NP to 1 NUT',
    powerUpTip1: 'Upgrade NP unlock period from {srcPeriod} week to {distPeriod} week.',
    powerUpTip2: 'Power up every 1 NUT to {distPeriod} NP',
    powerUpTip3: 'According to unlock period you chose, you can power up 1 NUT to 1-64 NP.',
    powerUpTip4: `You used to power up {nut} NUT to {np} NP. Now you can upgrade them to get more NP. Just chose one of the block below. Be attension, the unlocking period will grow simultaneously.`,
    powerUpTip5: '1 NUT to {ratio} NP',
    powerUpTip6: '{period} NP to {ratio} NP',
    powerUpTip7: 'unlock period: {unlockTime} weeks',
    nutStaked: "NUT Staked:",
    npAvailable: 'NP Available:',
    npVoted: 'NP Voted:',
    unlockPeriod: 'Unlock period:',
    unlockingNp: 'Unlocking NP',
    unlockingPeriod: 'Unlocking Period',
    unlockInWeeks: 'Unlock in {period} weeks：',
    covertTip: '{npAmount} NP to {nutAmount} NUT',
    daysLeft: ' days left',
    hoursLeft: ' hours left',
    minutesLeft: ' minutes left',
    secondsLeft: ' seconds left',
    upgradeTip1: `When you want to convert back your NP to NUT, you should do the power down operate, which will take
    <strong class="text-primary-1"> {distPeriod} weeks</strong>
    to get all your NUT back gradually.`
  },
  gauge: {
    voterCount: 'Voters',
    totalVoted: 'Total Voted',
    vote: "Vote",
    unvote: "Unvote"
  },
  asset: {
    tokenName: "Name",
    tokenSymbol: "Symbol",
    tokenAddress: "Token address",
    tokenId: "Token ID",
    wrongTokenId: "Token ID must be a integer number.",
    price: "Price",
    totalSupply: 'Supply',
    cap: 'Mkt Cap',
    distributionAmount: "Premint amount",
    notErc1155: "Token is not a ERC1155 token.",
    wrongAddress: 'Wrong Address',
    choseCToken: 'Choose a token as cToken',
    setDistr: 'Setting your asset distribution',
    totalDistr: 'Total distribution by current policy',
    currentBlock: 'Current Block height',
    tip1: 'The token you choose is mintable.',
    tip2: 'For your convenience, you can grant the mint authority to community contract. Then you need not reserve your token into community contract in the future times and times.',
    tip3: 'It should be noted that once you set this option, it cannot be changed in the future.',
    tip4: "If you don't understand the mechanism, just keep the box unchecked.",
    promiseGrant: 'Yes, I will grant mint role to community contract later.',
    tip5: 'Your community contract is deployed successfully!',
    tip6: 'Please confirm to grant the mint role to the contract.'
  },
  treasury: {
    treasuryAddress: "Treasury Address",
    daoTreasury: "DAO Treasury",
    treasuryAsset: "Treasury Asset",
    noCreatedTip: "You can create your community treasury.",
    createNote: "NOTE: once you create the treasury, anyone can redeem assets from DAO treasury use cToken as a share of treasury asset."
  },
  opHistory: {
    deposit: "Deposit {amount} {symbol} to {pool}",
    depositWithName: " deposit {amount} {symbol} to {pool}",
    add: "Add {amount} {symbol} to {delegatee} from {pool}",
    addWithName: " add {amount} {symbol} to {delegatee} from {pool}",
    withdraw: "Withdraw {amount} {symbol} from {pool}",
    withdrawWithName: " withdraw {amount} {symbol} from {pool}",
    sub: "Minus {amount} {symbol} to {delegatee} from {pool}",
    subWithName: " minus {amount} {symbol} to {delegatee} from {pool}",
    vote: "Vote {amount} {symbol} to {pool}",
    voteWithName: " vote {amount} {symbol} to {pool}",
    unvote: "Unvote {amount} {symbol} to {pool}",
    unvoteWithName: " unvote {amount} {symbol} to {pool}",
    harvestGauge: "Harvest {amount} {symbol} from {pool}",
    harvestGaugeWithName: " harvest {amount} {symbol} from {pool}",
    createGauge: 'Create a new pool vote for {pool}',
    createGaugeWithName: ' create a new pool vote for {pool}',
    withdrawGauge: "Harvest {amount} NUT from gauge",
    withdrawGaugeWithName: " harvest {amount} NUT from gauge",
    harvest: "Harvest {amount} {symbol} from {pool}",
    harvestWithName: " harvest {amount} {symbol} from {pool}",
    harvestAll: "Harvest all {symbol}",
    harvestAllWithName: " harvest all {symbol}",
    redeemTreasury: "Redeem from treasury by burn {amount} {symbol}",
    redeemTreasuryWithName: " redeem from treasury by burn {amount} {symbol}",
    create: "Create this community",
    createWithName: " create this community",
    setFee: "Change DAO fund ratio to {amount}%",
    setFeeWithName: " change DAO fund ratio to {amount}%",
    addPool: 'Create a new pool: {pool}',
    addPoolWithName: ' create a new pool: {pool}',
    closePool: "Close a pool: {pool}",
    closePoolWithName: " close a pool: {pool}",
    setRatio: "Reset the pool ratios",
    setRatioWithName: " reset the pool ratios",
    setDaofund: "Reset DAO fund address to : {address}",
    setDaofundWithName: " reset DAO fund address to: {address}",
    withdrawRevenue: "Withdraw revenue to {address}",
    withdrawRevenueWithName: " revenue to {address}",
    createTreasury: "Create treasury: {address}",
    createTreasuryWithName: " create treasury: {address}",
    changeCurationPoolRecipient: 'Admin change {pool} recipient to {address}',
    startCurationPool: "Start social pool: {pool}",
    startCurationPoolWithName: " start social pool: {pool}",
    title: {
      DEPOSIT: "Deposit",
      WITHDRAW: 'Withdraw',
      VOTE: "Vote pool",
      UNVOTE: "Unvote pool",
      WITHDRAWGAUGENUT: "Harvest NUT by NP vote",
      ADMINCREATENEWGAUGE: "Create pool vote",
      WITHDRAWGAUGECTOKEN: "Harvest C-Token by NP vote",
      ADMINWITHDRAWGAUGENUT: "Harvest NUT by NP vote",
      HARVEST: "Harvest",
      HARVESTALL: "Harvest All",
      REDEEMFROMTREASURY: "Redeem From Treasury",
      ADMINCREATE: "Create Community",
      ADMINSETFEE: "Set DAO fund ratio",
      ADMINADDPOOL: 'Create new pool',
      ADMINCLOSEPOOL: "Close Pool",
      ADMINSETRATIO: "Reset pool ratios",
      ADMINSETDAOFUND: "Reset DAO fund",
      ADMINWITHDRAWNREVENUE: "Withdraw revenue",
      ADMINCREATETREASURY: "Create treasury",
      ADMINCHANGECURATIONRECIPIENT: "Change recipient",
      STARTCURATIONGAUGE: "Start social pool"
    }
  }
};
