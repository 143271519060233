import { render, staticRenderFns } from "./StakingCardHeader.vue?vue&type=template&id=056f1204&scoped=true&"
import script from "./StakingCardHeader.vue?vue&type=script&lang=js&"
export * from "./StakingCardHeader.vue?vue&type=script&lang=js&"
import style0 from "./StakingCardHeader.vue?vue&type=style&index=0&id=056f1204&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "056f1204",
  null
  
)

export default component.exports